<div id="print-counting-sheet-popup">
  <div class="title">
    {{'printCountingSheet.printCountingSheet' | translate}}
  </div>
  <div class="content">
    <div class="loading-infos">
      <div>
        {{'printCountingSheet.productsLoading' | translate}} {{loadgingProgress | number:'1.2-2'}} {{' %'}}
      </div>
      <div>
        {{products.length }} {{ ' / '}} {{totalProducts}}
      </div>
    </div>
    <div class="progress-bar-container">
      <mat-progress-bar class="progress-bar" mode="determinate" [value]="loadgingProgress"></mat-progress-bar>
    </div>
  </div>
  <div class="footer-btn">
    <button class="sirh-btn-primary validate-btn" ngxPrint mat-raised-button printSectionId="print-section"
      [useExistingCss]="true" *ngIf="showPrintBtn">
      <mat-icon class="icon-button">check</mat-icon> {{ 'printCountingSheet.btns.print' | translate }}
    </button>
    <button class="sirh-btn-danger" mat-raised-button mat-dialog-close>
      <mat-icon class="icon-button">clear</mat-icon> {{ 'printCountingSheet.btns.cancel' | translate }}
    </button>
  </div>

  <div id="print-section" *ngIf="showPrintBtn" style="zoom: 0,5;" [hidden]="true">
    <app-counting-sheet-print [products]="products" [depositName]="depositName" [team]="team" [inventory]="inventory"
      [deposit]="deposit"></app-counting-sheet-print>
  </div>
</div>