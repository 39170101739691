
export const locale = {
    lang: 'fr',
    data: {
        printCountingSheet: {
            btns: {
                cancel: 'Annuler',
                print: 'Imprimer',
            },
            productsLoading: 'Chargement des produits... ',
            printCountingSheet: 'Impression de la fiche de comtage'
        },
    }
};