<header class="header-container">
  <div class="header-div">
    <div class="inventory-container">
      <span class="text-color" *ngIf="inventory">Inventaire {{'printCountingSheet.type.'+ this.inventory.type |
        translate}} du {{inventory.startDate}} au {{inventory.endDate}}</span>
    </div>
    <div class="title-container">
      <span class="text-color">Fiche de comptage N°</span>
    </div>
  </div>
  <div class="header-div">
    <div class="infos-container">
      <mat-icon>store_mall_directory</mat-icon>
      <span *ngIf="depositName">{{depositName}}</span>
    </div>
    <div class="infos-container">
      <mat-icon>group</mat-icon>
      <span *ngIf="team">{{team.name}}</span>
    </div>
    <div class="infos-container">
      <mat-icon>date_range</mat-icon>
      <span *ngIf="deposit && deposit.address">{{deposit.address}}</span>
    </div>
  </div>
</header>
<div class="counting-print-container">
  <mat-divider class="divider-style"></mat-divider>
  <div class="body-container">
    <div class="table-container" *ngFor="let product of products, index as i">
      <div class="table-header" *ngIf="(i === 0 || i%15 === 0 )" [ngClass]="(i > 1 ) ? 'page-break' : ''">
        <div class="location-part">Emplacement</div>
        <div class="ref-part">Ref</div>
        <div class="designation-part">Désignetion</div>
        <div class="counting-part">Comptage</div>
      </div>
      <div class="table-body">
        <div>
          <div class="table-body1" [ngClass]="(i%2 === 0) ? 'table-body2' : 'table-body1'">
            <span class="location-part">{{product.locationName}}</span>
            <span class="ref-part">{{product.reference}}</span>
            <span class="designation-part">{{product.productName}}</span>
            <div class="counting-part"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="footer" id="footer-document">
  <span>Signature</span>
  <div class="signature-container"></div>
</footer>