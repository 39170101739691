export const locale = {
    lang: 'dz',
    data: {
        printCountingSheet: {
            type: {
                PERMANENT: 'دائم',
                TURNING: 'دوري',
                ANNUAL: 'سنوي',
            }
        }
    }
}
