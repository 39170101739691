
export const locale = {
    lang: 'fr',
    data: {
        printCountingSheet: {
            type : {
                PERMANENT: 'Prmanent',
                TURNING: 'Tournant',
                ANNUAL: 'Annuel',
            }
        }
    }
}