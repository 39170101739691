import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CountingSheetService } from 'app/services/counting-sheet.service';
import { StorageUtils } from 'app/shared/storage-utils';
import { locale as francais } from "../print-conting-sheet-popup/i18n/fr";
import { locale as arabe } from "../print-conting-sheet-popup/i18n/dz";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPrintModule } from 'ngx-print';
import { CountingSheetPrintComponent } from '../../counting-sheet-print/counting-sheet-print.component';

@Component({
  selector: 'app-print-conting-sheet-popup',
  templateUrl: './print-conting-sheet-popup.component.html',
  styleUrls: ['./print-conting-sheet-popup.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressBarModule,
    TranslateModule,
    NgxPrintModule,
    CountingSheetPrintComponent
  ]
})
export class PrintContingSheetPopupComponent implements OnInit {
  totalProducts: number = 0;
  pageIndex: number = 0;
  inventoryId: number;
  teamId: number;
  countingSheetId: number;
  products: any[] = [];
  showPrintBtn: boolean = false;
  pageSize: number = 10;
  loadgingProgress: number = 0;
  importProducts: boolean = true;

  depositName: string;
  team: any;
  inventory: any;
  deposit: any;

  constructor(
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public currentDialog: MatDialogRef<PrintContingSheetPopupComponent>,
    private countingSheetService: CountingSheetService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(francais, arabe);

    this.teamId = this.data.teamId;
    this.inventoryId = this.data.inventoryId;
    this.countingSheetId = this.data.countingSheetId;
    this.team = this.data.team;
    this.inventory = this.data.inventory;
    this.deposit = this.data.deposit;
    this.depositName = this.deposit ? this.deposit.name : '';
  }

  ngOnInit() {
    this.getCountingSheetPoducts(this.pageIndex);
  }
  ngOnDestroy(): void {

    this.importProducts = false;

  }
  getCountingSheetPoducts(nextPage) {
    this.countingSheetService.getSingleCountingSheets(StorageUtils.getSelectedFolderId(), this.inventoryId, this.teamId, this.countingSheetId
      , nextPage, this.pageSize).subscribe(res => {
        if (!this.totalProducts) {
          this.totalProducts = res.headers.get('X-Total-Count') ? Number(res.headers.get('X-Total-Count')) : 0;
        }
        if (this.products.length < this.totalProducts && this.importProducts) {
          const data = res.body;
          data.forEach(product => {
            this.products.push(product);
            this.loadgingProgress = (this.products.length * 100) / this.totalProducts;
          });
          this.pageIndex++;
          this.getCountingSheetPoducts(this.pageIndex);
        }
        if (this.totalProducts === this.products.length) {
          this.showPrintBtn = true;
        }
      })
  }

}
