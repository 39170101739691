import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, RouterOutlet, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './services/auth.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SIRHLoaderModule } from './shared/components/sirh-loader/sirh-loader.module';
import { HttpErrorInterceptor } from './auth/http.interceptor';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CustoPrechargingModulesService } from './services/custo-precharging-modules.service';
import { NgxMaskDirective, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { QuantityValidationPopupComponent } from './shared/components/popUps/quantity-validation-popup/quantity-validation-popup.component';
import { PrintContingSheetPopupComponent } from './shared/components/popUps/print-conting-sheet-popup/print-conting-sheet-popup.component';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';

const appRoutes: Routes = [

    {
        path: '',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
        canActivateChild: [AuthGuard],
    },
    {
        path: 'login',
        component: AppComponent
    },
    {
        path: 'guest-register',
        component: AppComponent
    },
    {
        path: 'activate-account',
        component: AppComponent
    },

    {
        path: 'reset-password',
        component: AppComponent
    },
];
@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ], imports: [BrowserModule,
        BrowserAnimationsModule,
        SweetAlert2Module,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { preloadingStrategy: CustoPrechargingModulesService }),
        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        // App modules
        LayoutModule,
        NgHttpLoaderModule.forRoot(),
        SIRHLoaderModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        QuantityValidationPopupComponent,
        PrintContingSheetPopupComponent,
        RouterModule.forRoot(appRoutes),
        RouterOutlet,
        TranslateModule.forRoot(),
        TranslateModule,
        NgHttpLoaderModule,
        CommonModule,
        RouterModule,
        MatDialogModule,
        MatButtonModule,
        FlexLayoutModule,
        RouterOutlet,
        FuseModule,
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        LayoutModule,
        SIRHLoaderModule,
        QuantityValidationPopupComponent,
        PrintContingSheetPopupComponent,
        NgxMaskDirective], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: CacheInterceptor,
        //     multi: true
        // },
        AuthService,
        provideNgxMask(),
        provideEnvironmentNgxMask(),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
