import { Component, Input, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as francais } from "../counting-sheet-print/i18n/fr";
import { locale as arabe } from "../counting-sheet-print/i18n/dz";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-counting-sheet-print',
  templateUrl: './counting-sheet-print.component.html',
  styleUrls: ['./counting-sheet-print.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    TranslateModule
  ]
})
export class CountingSheetPrintComponent implements OnInit {

  @Input() products: any[];
  @Input() depositName: any;
  @Input() deposit: any;
  @Input() team: any;
  @Input() inventory: any;

  constructor(
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translate: TranslateService,) {
    this.fuseTranslationLoaderService.loadTranslations(francais, arabe);
  }

  ngOnInit() {
  }

}
